var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"v5.10.19"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

/* eslint-disable jest/require-hook */

import * as Sentry from '@sentry/nextjs'

const SENTRY_ENVIRONMENT = process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT

Sentry.init({
  dsn: 'https://aa7f606ca8d757382308c944a4b285be@o216259.ingest.us.sentry.io/4507961848496128',
  environment: SENTRY_ENVIRONMENT,

  profilesSampleRate: 0.01,
  replaysOnErrorSampleRate: 0.0,
  replaysSessionSampleRate: 0.0,
  sampleRate: 0.01,
  tracesSampleRate: 0.01,
})
